import type { Strapi4RequestParams } from '@nuxtjs/strapi'
import { stringify } from 'qs'
import type { NitroFetchOptions } from 'nitropack'
import { useSentry } from '~/composables/sentry/useSentry'

type requestParams = {
  customQuery?: {
    [key: string]: string
  }
} & Strapi4RequestParams

const RETRY_COUNT = 5
const RETRY_DELAY_MS = 500

export function apiRequest<ReturnType extends object>(
  url: string,
  requestParams: requestParams = {},
  options: Partial<NitroFetchOptions<any>> = {},
): Promise<ReturnType> {
  const { customQuery, ...allParams } = requestParams

  const params = stringify({
    ...allParams,
    ...(customQuery || {}),
  }, { encodeValuesOnly: true })

  return new Promise((resolve, reject) => {
    $fetch(params ? `${url}?${params}` : url, {
      ...options,
      retry: RETRY_COUNT,
      retryDelay: RETRY_DELAY_MS,
      baseURL: typeof useStrapiUrl !== 'undefined' ? useStrapiUrl() : `${process.env.STRAPI_URL}/api`,
      async onRequestError(context) {
        if (!context.options.retry) {
          await handleError(context)
        }
      },
      async onResponseError(context) {
        if (!context.options.retry) {
          await handleError(context)
        }
      },
    }).then((data) => {
      resolve(data as ReturnType)
    }).catch(reject)
  })
}

async function handleError(context: { error?: Error }) {
  await useSentry().captureException(
    context.error || new Error('Unknown error'),
    { context },
  )
}
