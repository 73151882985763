import type { Strapi4Response, Strapi4ResponseData } from '@nuxtjs/strapi'
import type { IssuesCollateralType } from './../modules/issues/services/getIssuesList/getIssuesList.types'
import { transformMultiselectValue } from '~/helpers/functions'
import type { WithId } from '~/api/types'

export function simplifyStrapiCollectionResponse<T>(response: Strapi4Response<T>): WithId<T>[] {
  return (response.data as Strapi4ResponseData<T>[])
    .map(({ attributes, id }) => ({ id, ...attributes } as WithId<T>))
}

export function simplifyStrapiResponse<T>(response: Strapi4Response<T>): T {
  return (response.data as Strapi4ResponseData<T>)?.attributes
}

export type StrapiDeepContent<T> = {
  data: {
    id: number
    attributes: T
  }
}

export function transformStrapiDeep<T>(content?: { data: { attributes: T } }): T | null {
  return content?.data?.attributes || null
}

export function transformCollateralTypes(types: string): IssuesCollateralType[] {
  return transformMultiselectValue(types) as IssuesCollateralType[]
}

export function buildFileCategoriesPopulate(keys: string[]) {
  return keys.reduce((acc, key) => {
    acc[key] = {
      populate: {
        files: {
          populate: { file: { fields: ['name', 'url'] } },
        },
      },
    }
    return acc
  }, {} as Record<string, any>) // TODO: fix any
}
